.documenthtml {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 300;
	color: #333333;
	margin-bottom: 30px;
}

	.documenthtml td, .documenthtml li, .documenthtml p {
		font-size: 14px;
		line-height: 24px;
		font-weight: 300;
		color: #333333;
	}

	.documenthtml strong {
		font-weight: 900;
	}

.documenthtml [data-history='true'] {
	padding-top: 80px;
}

	.documenthtml .lid {
		margin-bottom: 16px;
	}

	.documenthtml .artikel {
		margin-bottom: 32px;
	}

	.documenthtml h1 {
		margin-top: 0;
		margin-bottom: 16px;
		font-size: 24px;
		font-weight: 300;
		color: #203666;
		line-height: 38px;
	}

	.documenthtml h2 {
		font-weight: 300;
		font-size: 20px;
		color: #203666;
		line-height: 32px;
	}

	.documenthtml h3 {
		font-size: 18px;
		font-weight: 300;
		color: #203666;
		line-height: 28px;
	}

	.documenthtml h4, h5, h6 {
		font-size: 16px;
		font-weight: bold;
		line-height: 20px;
		color: #203666;
	}

	.documenthtml .artikel h1, .documenthtml .artikel h2, .documenthtml h1.artikel, .documenthtml .artikel h3, .documenthtml .artikel h4 {
		font-size: 14px;
		font-weight: bold;
		color: #203666;
		margin-bottom: 0;
	}

	.documenthtml h1.lidkop, .documenthtml h2.lidkop, .documenthtml h3.lidkop, .documenthtml h1.lidkop, .documenthtml h4.lidkop, .documenthtml h5.lidkop, .documenthtml h6.lidkop {
		font-size: 14px;
		font-weight: 900;
		display: inline;
		color: #203666;
	}

	.documenthtml .lidkop ~ p {
		margin-left: 4px;
		display: inline;
		clear: both;
	}

		.documenthtml .lidkop ~ p ~ * {
			margin-top: 16px;
		}


	.documenthtml img {
		max-width: 100%;
	}

	.documenthtml table[border='1'] {
		border-collapse: collapse;
	}


	.documenthtml table caption {
		margin-bottom: 8px;
		text-align: left;
		font-style: italic;
	}

	.documenthtml ul {
		padding: 0;
		margin-left: 16px;
		list-style-type: none;
	}

		.documenthtml ul.bullet {
			list-style-type: square;
		}

	.documenthtml ol {
		padding: 0;
		margin-left: 20px;
	}

		.documenthtml ol.sdulist {
			list-style: none;
			margin-left: 0;
			padding: 0;
		}


			.documenthtml ol.sdulist ol.sdulist {
				list-style: none;
				margin-left: 16px;
				padding: 0;
			}

	.documenthtml table.sdulist {
		margin-bottom: 16px;
	}

		.documenthtml table.sdulist td {
			vertical-align: top;
		}

	.documenthtml p.nr {
		display: inline;
		margin-right: 4px;
	}

		.documenthtml p.nr ~ p {
			display: inline;
		}

	.documenthtml .brispub_tablearea {
		margin-bottom: 30px;
		overflow-x: auto;
	}

	.documenthtml .brispub_table {
		empty-cells: show;
	}

		.documenthtml .brispub_table td {
			vertical-align: top;
			padding: 2px 6px;
		}

			.documenthtml .brispub_table td[valign='top'] {
				vertical-align: top;
			}

			.documenthtml .brispub_table td[valign='middle'] {
				vertical-align: middle;
			}

			.documenthtml .brispub_table td[valign='bottom'] {
				vertical-align: bottom;
			}

			.documenthtml .brispub_table td[valign='baseline'] {
				vertical-align: baseline;
			}



	.documenthtml ul li, .documenthtml ol li {
		vertical-align: top;
	}

	.documenthtml a, .documenthtml a:visited, .documenthtml a:active {
		color: #7180A2;
		text-decoration: none;
	}

		.documenthtml a:hover {
			color: #7180A2;
			text-decoration: underline;
		}

		.documenthtml a.lmmultiple, .documenthtml a.lmmultiple:visited, .documenthtml a.lmmultiple:active {
			color: #00a951;
			text-decoration: none;
		}

			.documenthtml a.lmmultiple:hover {
				color: #00a951;
				text-decoration: underline;
			}

	.documenthtml #PdfContainer {
		margin-top: 0;
		width: 100%;
	}

	.documenthtml .opmerking {
		background-color: #E9F0FA;
		padding: 15px 15px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.documenthtml .opmerkingregeling {
		background-color: #E9F0FA;
		padding: 15px 15px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.documenthtml .opmerkingbris {
		background-color: #E9F0FA;
		padding: 15px 15px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.documenthtml .kader {
		border: 1px solid #555;
		padding: 15px 15px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.documenthtml .opmerking .titel, .documenthtml .opmerkingregeling .titel, .documenthtml .opmerkingbris .titel {
		font-weight: bold;
	}

	.documenthtml .km-block.error {
		background-color: #FFF0F0;
		padding: 8px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.documenthtml .inspringen {
		margin-left: 40px;
	}

del {
	color: #ff0000;
}

ins {
	color: #0000ff;
}

.orderbutton {
	font-size: 14px;
	padding: 10px 30px;
	background-color: #F2BB2A;
	color: #fff !important;
	border-radius: 0;
}

	.orderbutton:hover {
		text-decoration: none !important;
		background-color: #F5CA5A !important;
	}
