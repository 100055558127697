.document-area {
	border-bottom: #D8D8D8
}

	.document-area .document-title {
		color: #203666;
		font-weight: 700;
		font-size: 28px;
	}

	.document-area .document-toolbar {
		text-align: right;
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #D8D8D8;
	}

	.document-area .document-toc .toc-item {
		font-size: 14px;
		display: inline-block;
		position: relative;
		left: 15px;
		color: #203666;
		padding-left: -15px;
		padding-right: -15px;
	}

	.document-area .document-toc .toc-childgroup .toc-togglebtn {
		background-color: transparent;
	}

	.document-area .document-toc .toc-togglebtn .fa-caret-right,
	.document-area .document-toc .toc-togglebtn .fa-caret-down {
		position: absolute;
		color: #203666;
		margin-top: 5px;
		cursor: pointer;
	}

	.document-area .document-toc .toc-item-area {
		padding: 5px 20px 5px 5px;
		border-left: 7px solid transparent;
		margin-left: -10px;
		margin-right: -10px;
	}

		.document-area .document-toc .toc-item-area.selected {
			background-color: #203666;
			border-left-color: #ED7900;
		}

			.document-area .document-toc .toc-item-area.selected .toc-item {
				color: #FFFFFF;
				margin-right: 5px;
			}

			.document-area .document-toc .toc-item-area.selected .toc-togglebtn .fa-caret-right,
			.document-area .document-toc .toc-item-area.selected .toc-togglebtn .fa-caret-down {
				color: #FFFFFF;
			}
