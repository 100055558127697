.recentlist .recent {
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 8px;
}

.recentlist .recent h3 {
	font-size: 14px;
	font-weight: 900;
	color: #203666;
	margin-bottom: 0;
}

	.recentlist .recent .coverimage {
		width: 40px;
		margin-right: 2px;
	}

	.recentlist .recent .recent-subtitle {
		font-size: 12px;
	}


	.recentlist .recent .recent-link {
		font-size: 14px;
		font-weight: 900;
	}
