.history-table {
	margin: -15px -15px;
}

	.history-table .historytyperow.hidden {
		display: none;
	}

	.history-table .historytypeblock {
		height: 150px;
		overflow-y: visible;
		text-align: center;
		vertical-align: middle;
	}

	.history-table .blueback {
		background-color: #7080A1;
		
	}

	.history-table .orangeback {
		background-color: #F3A859;
	}

	.history-table .greenback {
		background-color: #318237;
	}

.history-revision-caption {
	color: #FFFFFF;
	font-size: 22px;
	font-weight: 500;
	transform: rotate(90deg);
}

.historylist .historyitem {
	padding: 15px 15px;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 300;
	color: #333333;
}

	.historylist .historyitem.historyitem-even {
		background-color: #F6F6F6;
	}

	.historylist .historyitem.historyitem-odd {
		background-color: #FFFFFF;
	}

	.historylist .historyitem h3 {
		font-size: 18px;
		font-weight: 900;
		color: #203666;
		margin-bottom: 15px;
	}

	.historylist .historyitem .history-subtitle {
		margin-top: 0;
		margin-bottom: 0;
		color: #333;
		margin-bottom: 15px;
	}

	.historylist .historyitem .history-eye {
		float: right;
	}

	.historylist .historyitem .history-pubdate {
		color: #777;
	}

	.historylist .historyitem .history-link {
		font-weight: 900;
	}
