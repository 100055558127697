.resultlist h2 {
	font-family: 'Open Sans', sans-serif;
	font-size: 20px;
	font-weight: 900;
	color: #203666;
	margin-bottom: 0;
}

.resultlist .statistics {
	text-align: right;
	margin-bottom: 15px;
}

.resultlist .result {
	padding: 15px 15px;
	margin-bottom: 15px;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 300;
	color: #333333;
}

	.resultlist .result.result-even {
		background-color: #F6F6F6;
	}

	.resultlist .result.result-odd {
		background-color: #FFFFFF;
	}

	.resultlist .result .coverimage {
		width: 80px;
		margin-right: 15px;
	}

	.resultlist .result h3 {
		font-size: 18px;
		font-weight: 900;
		color: #203666;
		margin-bottom: 0;
	}

	.resultlist .result .pubdate {
		color: #949292;
		font-size: 12px;
	}


	.resultlist .result .subtitle {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 14px;
		font-weight: 900;
	}

	.resultlist .result .summary {
		margin-top: 0;
	}


	.resultlist .result .link {
		float: right;
		font-weight: 900;
	}

.resultlist .badge {
	font-size: 12px;
	font-weight: 300;
	margin-right: 8px;
}
