.header-topline img.logo {
	height: 96px;
	margin: 10px 0 10px  -15px;
	/*cursor: pointer;*/
}

.header-topline .last-col {
	text-align: right;
	padding-top: 8px;
}

	.header-topline .last-col p {
		font-family: 'Open Sans', sans-serif;
		position: relative;
		right: 0;
		display: inline-block;
		margin-top: 8px;
	}

		.header-topline .last-col p strong {
			color: #203666;
		}


@media (max-width: 768px) {
	.header-topline .last-col {
		display: none;
	}
}

.naw {
    line-height: 22px;
    font-size: 15px;
}
