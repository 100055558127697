.kvlookuplist .kvlookupitem {
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 10px;
}

	.kvlookuplist .kvlookupitem h3 {
		font-size: 14px;
		font-weight: 900;
		color: #203666;
		margin-bottom: 0;
	}

	.kvlookuplist .kvlookupitem .coverimage {
		width: 40px;
		margin-right: 2px;
	}

	.kvlookuplist .kvlookupitem .subtitle {
		font-size: 12px;
		color: #333;
	}

	.kvlookuplist .kvlookupitem .kvlookupitem-link {
		font-size: 14px;
		font-weight: 900;
	}

.kvlookuplist .kvlookuplist-statistics {
	margin-bottom: 20px;
	font-size: 13px;
}

@media (max-width: 1200px) {
	.borderbox h2 {
		font-size: 18px !important;
	}
}

@media (max-width: 992px) {
	.borderbox h2 {
		font-size: 12px !important;
	}
}

@media (max-width: 768px) {
	.borderbox h2 {
		font-size: 12px !important;
	}
}
