.facetlist .group-title {
	font-family: 'Open Sans', sans-serif;
	font-size: 18px;
	font-weight: 900;
	color: #203666;
	margin-bottom: 0;
}


	.facetlist .facet-checkbox {
		margin-left: -20px;
		display: inline;
	}

.facetlist .facet {
	margin-bottom: 0;
}

.facetlist .badge {
	display: inline-block;
	position: relative;
	top: -4px;
	margin-left: 0;
	font-family: 'Open Sans', sans-serif;
	font-size: 10px;
	font-weight: 300;
}

.filters .custom-control-label {
	font-size: 13px !important;
}







