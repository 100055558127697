.linkmanager-table {
	margin: -15px -15px;
}

	.linkmanager-table .blueback {
		background-color: #7080A1;
		height: 150px;
		overflow-y: visible;
	}


.linkmanager-revision-caption {
	background-color: #7080A1;
	color: #FFFFFF;
	font-size: 22px;
	font-weight: 500;
	transform: rotate(90deg);
}

.linkmanagerlist .linkmanageritem {
	padding: 15px 15px;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 300;
	color: #333333;
}

	.linkmanagerlist .linkmanageritem.linkmanageritem-even {
		background-color: #F6F6F6;
	}

	.linkmanagerlist .linkmanageritem.linkmanageritem-odd {
		background-color: #FFFFFF;
	}

.linkmanagerlist .linkmanageritem h3 {
	font-size: 18px;
	font-weight: 900;
	color: #203666;
	margin-bottom: 15px;
}

.linkmanagerlist .linkmanageritem .linkmanager-subtitle {
	margin-top: 0;
	margin-bottom: 0;
	color: #333;
	margin-bottom: 15px;
}

.linkmanagerlist .linkmanageritem .linkmanager-pubdate {
	color: #777;
}

.linkmanagerlist .linkmanageritem .linkmanager-link {
	font-weight: 900;
}
