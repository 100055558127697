.inputquery-area {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 15px;
}

.inputquery-textarea {
	width: 100%;
	padding: 0;
	margin: 0;
}

.inputquery-inputarea {
	width: 100%;
	padding: 0;
	margin: 0;
}

.inputquery-area .inputquery-text {
	width: 100%;
}

	.inputquery-area .inputquery-text.small {
		font-size: 14px;
	}

.inputquery-buttonarea .inputquery-button.wide {
	min-width: 120px;
}

.inputquery-buttonarea .inputquery-button.small {
	min-height: 30px;
}

.advertisement {
	background-color: #203666;
	text-align: center;
	margin-bottom: 15px;
}

	.advertisement .logo {
		width: 150px;
		margin: 40px 0;
	}

@media (max-width: 992px) {
    .advertisement .logo {
        width: 100px;
    }
}

@media (max-width: 772px) {
    .deletesearchquery  {
        display: none;
    }
}

.deletesearchquery {
  position: absolute;
  margin-left: -20px;
  margin-top: 8px;
  cursor: pointer;
  color: #777;
}

    .deletesearchquery:hover {
        color: #000;
    }
