.osfbrllinklist .osfbrllinkitem {
	font-family: 'Open Sans', sans-serif;
	margin-bottom: 10px;
}

	.osfbrllinklist .osfbrllinkitem h3 {
		font-size: 14px;
		font-weight: 900;
		color: #203666;
		margin-bottom: 0;
	}

	.osfbrllinklist .osfbrllinkitem .coverimage {
		width: 40px;
		margin-right: 2px;
	}

	.osfbrllinklist .osfbrllinkitem .subtitle {
		font-size: 12px;
		color: #333;
	}

	.osfbrllinklist .osfbrllinkitem .osfbrllinkitem-link {
		font-size: 14px;
		font-weight: 900;
	}
