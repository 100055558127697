body {
	font-family: 'Open Sans', sans-serif;
	margin: 0 20px;
	overflow-x: hidden;
}


p, td, li {
	font-family: 'Open Sans', sans-serif;
}

.clear {
	clear: both;
}

.nopadding {
	padding: 0 0 0 0;
}

.nopadding-right {
	padding: 0 0 0 30px;
}

@media (max-width: 992px) {
	.nopadding-right {
		padding: 0 0 0 0;
	}
}

@media (max-width: 768px) {
	.nopadding-right {
		padding: 0 0 0 0;
	}
}

.minwidth {
	width: 1px;
}

.maxwidth {
	width: 100%;
}

.valign-top {
	vertical-align: top;
}

.align-right {
	text-align: right;
}

.form-control, .btn {
	border-radius: 0;
}

.btn {
	min-height: 38px;
}

.btn-round {
	border-radius: 5px;
	margin-left: 2px;
	min-height: inherit;
}

	.btn-round.hidden {
		display: none;
	}

	.btn-round.left {
		float: left;
	}

.borderbox {
	border: 1px solid #D8D8D8;
	padding: 10px 10px;
	margin-bottom: 15px;
}

	.borderbox h2 {
		font-family: 'Open Sans', sans-serif;
		font-size: 20px;
		font-weight: 700;
		color: #203666;
	}

@media (max-width: 1200px) {
	.borderbox h2 {
		font-size: 17px !important;
	}
}

@media (max-width: 992px) {
	.borderbox h2 {
		font-size: 20px !important;
	}
}

@media (max-width: 768px) {
	.borderbox h2 {
		font-size: 20px !important;
	}
}

.custom-switch .custom-control-label, .custom-checkbox .custom-control-label {
	position: relative;
	margin-top: 4px;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	color: #333;
	cursor: pointer;
}

	.custom-switch .custom-control-label:hover, .custom-checkbox .custom-control-label:hover {
		color: #ED7900;
	}

.scrollxauto {
	overflow-x: auto;
}

@media (max-width: 768px) {
	.hidden-xs {
		display:none;
	}
}

@media (max-width: 992px) {
	.hidden-sm {
		display: none;
	}
}

@media (max-width: 1200px) {
	.hidden-md {
		display: none;
	}
}

.printframe {
	position: absolute;
	top: -1000px;
	left: -1000px;
	width: 0;
	height: 0;
}



