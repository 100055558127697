.cover {
	font-size: 14px;
	margin-bottom: 20px;
}

	.cover .cover-maininfo {
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid #D8D8D8;
	}

		.cover .cover-maininfo td {
			vertical-align: top;
		}

		.cover .cover-maininfo h2 {
			color: #203666;
			font-weight: 700;
			font-size: 28px;
			line-height: 30px;
		}

	.cover .cover-maininfo h3 {
		font-weight: 700;
		font-size: 18px;
		line-height: 28px;
	}

		.cover .cover-maininfo .coverimage {
			width: 130px;
			margin-left: 15px;
		}

		.cover td, .cover li, .cover p {
			font-size: 14px;
		}

	.cover .cover-metadata {
		margin-bottom: 30px;
		padding-bottom: 15px;
		border-bottom: 1px solid #D8D8D8;
	}

		.cover .cover-metadata .cover-metadata-row {
			margin-bottom: 15px;
		}

		.cover .cover-metadata .name, .cover .cover-copyrights .name {
			font-weight: 900;
		}

		.cover .cover-metadata .value, .cover .cover-copyrights .value {
			color: #777;
		}
