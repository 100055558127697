.osflookuplist .osflookupitem {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

  .osflookuplist .osflookupitem h3 {
    font-size: 14px;
    font-weight: 900;
    color: #203666;
    margin-bottom: 0;
  }

  .osflookuplist .osflookupitem .coverimage {
    width: 40px;
    margin-right: 2px;
  }

  .osflookuplist .osflookupitem .subtitle {
    font-size: 12px;
    color: #333;
  }

  .osflookuplist .osflookupitem .osflookupitem-link {
    font-size: 14px;
    font-weight: 900;
  }

.osflookuplist .osflookuplist-statistics {
  margin-bottom: 20px;
  font-size: 13px;
}
