.footer-topline h4 {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 900;
	color: #203666;
	text-transform: uppercase;
}

.footer-topline p {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
}

.footer-topline img.logo {
	position: absolute;
	width: 60px;
	margin-top: -25px;
	margin-left: -15px;
}

.footer-topline .first-col h4 {
	margin-left: 60px;
}

.footer-topline .first-col p {
	margin-left: 60px;
}

.footer-bottomline p {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	margin-top: 15px;
	text-align: right;
}

@media (max-width: 768px) {
	.footer-topline img.logo {
		display: none;
	}

	.footer-topline .first-col h4 {
		margin-left: 0;
	}

	.footer-topline .first-col p {
		margin-left: 0;
	}
}
