.modal-dialog {
	border-radius: 0;
}

	.modal-dialog .modal-content {
		border-radius: 0;
		background-color: #203666;
	}

		.modal-dialog .modal-content .modal-header {
			background-color: #203666;
		}

			.modal-dialog .modal-content .modal-header .close {
				color: #FFFFFF;
				opacity: 1;
				text-shadow: none;
			}
			.modal-dialog .modal-content .modal-header .close:hover {
				color: #ED7900;
			}

		.modal-dialog .modal-content .modal-header .modal-title {
			color: #FFFFFF;
		}


		.modal-dialog .modal-content .modal-body {
			background-color: #FFFFFF;
		}
		



