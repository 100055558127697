.mainmenu {
  height: 80px;
  border-top: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8;
  border-left: 1px solid #D8D8D8;
  border-bottom: 5px solid #203666;
  margin-bottom: 15px;
}

  .mainmenu .menu-item {
    position: relative;
    top: -1px;
    font-family: 'Open Sans', sans-serif;
    display: inline-block;
    width: 200px;
    height: 80px;
    border: 1px solid #D8D8D8;
    border-bottom: 5px solid #203666;
    padding: 10px 10px;
    text-decoration: none;
    background-color: #FFFFFF;
  }

    .mainmenu .menu-item.selected {
      border-bottom: 5px solid #ED7900;
    }

    .mainmenu .menu-item:hover {
      text-decoration: none;
      background-color: #F6F6F6;
    }

    .mainmenu .menu-item.disabled:hover {
      text-decoration: none;
      background-color: #FFFFFF;
    }

    .mainmenu .menu-item .menu-item-caption {
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      font-weight: 900;
      color: #203666;
      text-transform: uppercase;
    }

    .mainmenu .menu-item.selected .menu-item-caption {
      color: #ED7900;
    }

    .mainmenu .menu-item.disabled .menu-item-caption {
      color: #999;
    }


    .mainmenu .menu-item .menu-item-description {
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 13px;
      color: #333;
    }

    .mainmenu .menu-item.disabled .menu-item-description {
      color: #999;
    }

@media (max-width: 768px) {
  .mainmenu .menu-item {
    width: 150px;
    padding-top: 24px;
  }

    .mainmenu .menu-item .menu-item-caption {
      font-size: 18px;
    }

    .mainmenu .menu-item .menu-item-description {
      display: none;
    }
}

.kennisid-area {
	margin-top: -56px;
	text-align: right;
	color: #555;
}

	.kennisid-area .kennisid-link {
		text-decoration: underline;
		color: #203666;
	}

	.kennisid-area .kennisidlogo {
		width: 40px;
		height: 40px;
		margin-top: -4px;
		margin-right: 8px;
		margin-left: 4px;
	}
